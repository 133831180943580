import React, { useCallback } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import logo from '../assets/images/bcore.white.platform.logo.divisions.png'
import Particles from 'react-particles'
import particlesOptions from '../assets/particles.json'
import { loadFull } from 'tsparticles'
import { Button } from '@material-ui/core'

export default function Banner(props) {
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine)
  }, [])

  const { markdownRemark, allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        markdownRemark(fileAbsolutePath: { regex: "/tagline/" }) {
          frontmatter {
            comp_tagline
          }
        }
      }
    `
  )

  return (
    <section id="banner" className="major">
      <div className="container">
        <Particles
          options={particlesOptions}
          init={particlesInit}
          width="100%"
          height="100vh"
        />
      </div>
      <div className="inner">
        <div id="major-logo-container">
          <img src={logo} alt="website logo" />
        </div>
        <div className="content">
          <p className="centered-content">
            {markdownRemark.frontmatter.comp_tagline}
          </p>
          <p className="centered-content">
            <Button
              style={{ color: 'white', borderColor:'white', borderWidth: '0.25em' }}
              size='large'
              variant="outlined"
              href="https://careers-geoyeti.icims.com/jobs/"
            >
              <strong>Explore Career Opportunities</strong>
            </Button>
          </p>
        </div>
      </div>
    </section>
  )
}
