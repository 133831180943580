import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

class HomeIndex extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet
          title="Home - Geoyeti"
          meta={[
            { name: 'description', content: 'Geoyeti Home page' },
            { name: 'keywords', content: 'Geoyeti' },
          ]}
        ></Helmet>
        <Banner />
      </Layout>
    )
  }
}

export default HomeIndex
